import React from 'react';
import ReactDOM from 'react-dom/client';
import { lazy, Suspense } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import AuthContextProvider from './Context/AuthContextProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Logo from './Logo/Logo.jpg'

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider>
    <BrowserRouter>
      <AuthContextProvider>
        <Suspense fallback={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <img src={Logo} style={{ width: '30%', margin: 'auto' }} alt="Logo" />
        </div>} >
          <App />
        </Suspense>
      </AuthContextProvider>
    </BrowserRouter>
  </ChakraProvider>
);

reportWebVitals();
